import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container } from 'react-grid-system';
import Button from 'components/Button';
import breakpoints from 'utils/breakpoints';
import { useScrollTo } from 'utils';
import Markdown from 'react-markdown';
import { HEADER_HEIGHT } from 'components/Header';
import Background from './Background';
import EarthCanvas from './EarthCanvas';

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  background: #212121;
  margin-top: -${HEADER_HEIGHT}px;
  padding-top: ${HEADER_HEIGHT}px;
  height: 29.19rem;
  display: flex;
  align-items: center;

  @media screen and (min-width: ${breakpoints.md}) {
    height: 42.56rem;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 3;
  top: -${HEADER_HEIGHT / 2}px;

  @media screen and (min-width: ${breakpoints.md}) {
    max-width: 56.25rem;
    margin: 0 auto;
  }
`;

const Title = styled.h2`
  color: #fff;
  font-family: Helvetica;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.75rem;
  text-align: center;
  margin-bottom: 1.5rem;
  p {
    margin: 0;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    margin: 0 0 5rem;

    text-align: center;
    font-size: 2.63rem;
    line-height: 3.13rem;
  }
`;

const Description = styled.p`
  color: #fff;
  font-family: 'Be Vietnam';
  font-weight: 300;
  font-size: 0.88rem;
  line-height: 1.31rem;
  text-align: center;
  margin-bottom: 3rem;

  @media screen and (min-width: ${breakpoints.md}) {
    font-size: 1rem;
    line-height: 1.44rem;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

function Hero({ className, title, description }) {
  const scrollToSuccessStories = useScrollTo('productSection');

  return (
    <Wrapper className={className}>
      <Content>
        <Container>
          {title && (
            <Title>
              <Markdown source={title} />
            </Title>
          )}
          {description && <Description>{description}</Description>}
          <ButtonWrapper>
            <Button onClick={scrollToSuccessStories}>See how it works</Button>
          </ButtonWrapper>
        </Container>
      </Content>
      <EarthCanvas />
      <Background />
    </Wrapper>
  );
}

Hero.defaultProps = {
  className: null,
  title: null,
  description: null,
};

Hero.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Hero;
