import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Col, Row } from 'react-grid-system';
import Img from 'components/Img';
import Heading from 'components/Heading';
import ItemListSimple from 'components/ItemList/Simple';
import capacity from 'assets/home/capacity.svg';
import breakpoints from 'utils/breakpoints';

const Wrapper = styled.section`
  padding: 85px 0;
  h3 {
    text-align: center;
  }
  @media screen and (min-width: ${breakpoints.md}) {
    padding: 140px 0;

    h3 {
      text-align: left;
    }
  }
`;

const ImgWrap = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (min-width: ${breakpoints.lg}) {
    margin: 0;
  }
`;

const StyledImg = styled(Img)`
  width: 100%;
`;

function CapacitySection({ className, title, list }) {
  return (
    <Wrapper className={className}>
      <Container>
        <Row align="center" justify="center">
          <Col lg={6}>
            <ImgWrap>
              <StyledImg publicURL={capacity} alt={title} />
            </ImgWrap>
          </Col>
          <Col xs={10} lg={5} offset={{ lg: 1 }}>
            <Heading title={title} />
            <ItemListSimple items={list} />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

CapacitySection.defaultProps = {
  className: null,
};

CapacitySection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  list: ItemListSimple.propTypes.items.isRequired,
};

export default CapacitySection;
