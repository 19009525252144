import React from 'react';
import PropTypes from 'prop-types';
import { ParallaxProvider } from 'react-scroll-parallax';
import styled from 'styled-components';
import Layout from 'components/Layout';
import { dark } from 'themes';
import Hero from 'components/Home/Hero';
import OurProducts from 'components/Home/OurProducts';
import DataPrivacy from 'components/Home/DataPrivacy';
import Cards from 'components/Home/Cards';
import Clients from 'components/Home/Clients';
import Capacity from 'components/Home/Capacity';
import { graphql } from 'gatsby';

const Wrapper = styled.div`
  background: #fff;
  color: #fff;
`;

const HomePage = ({ data }) => {
  const { homeJson, partnersJson } = data;
  const { intro, cards, ourProducts, capacity, dataPrivacy } = homeJson;

  return (
    <ParallaxProvider>
      <Layout headerTheme={dark} overflow pageTitle="Home">
        <Wrapper>
          <Hero fixed {...intro} />
          <Cards cards={cards} />
          <OurProducts {...ourProducts} />
          <Capacity {...capacity} />
          <DataPrivacy {...dataPrivacy} />
          <Clients {...partnersJson} />
        </Wrapper>
      </Layout>
    </ParallaxProvider>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    homeJson: PropTypes.shape({
      intro: PropTypes.shape(Hero.propTypes),
      cards: Cards.propTypes.cards,
      ourProducts: PropTypes.shape(OurProducts.propTypes),
      capacity: PropTypes.shape(Capacity.propTypes),
      dataPrivacy: PropTypes.shape(DataPrivacy.propTypes),
    }),
    partnersJson: PropTypes.shape(Clients.propTypes),
  }).isRequired,
};

export default HomePage;

export const query = graphql`
  {
    homeJson {
      intro {
        title
      }

      ourProducts {
        description
        links {
          name
          link
          image
          description
        }
        title
      }

      dataPrivacy {
        title
        cards {
          description
          iconType
          title
        }
      }

      cards {
        description
        iconType
      }

      capacity {
        title
        list {
          description
          iconType
        }
      }
    }

    partnersJson {
      heading
      logos {
        alt
        image
      }
    }
  }
`;
